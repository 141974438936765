import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import {
  Botton_action,
  modal_position,
  modal_size,
  modules,
  titulos,
} from "app/@components/confi/confi";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import * as uuid from "uuid";
import * as indexActions from "app/@components/general/ngrx/index/index.actions";
import * as generalActions from "app/@components/general/ngrx/general.actions";
import { takeUntil } from "rxjs/operators";
import { addDays } from "date-fns";
import { Empresa } from "app/@components/models/Empresa";
import {
  RegistroUsuarioProyecto,
  diaYfecha,
} from "app/@components/models/RegistroUsuarioProyecto";
import { CrudDeletePopupComponent } from "app/@components/general/componentes-crud/crud-delete-popup/crud-delete-popup.component";
import { CustomValidatorsService } from "app/@components/validators/AppCustomDirective";
import * as moment from "angular2-moment/node_modules/moment";
import { RespuestaService } from "app/@components/respuesta.service";
import { ShowToastService } from "app/@components/showToast.service";
import { RegistroAsignacionProyecto1PopupAddUpdComponent } from "../popup1AddUpd/registroAsignacionProyecto1PopupAddUpd.component";
import { da, th } from "date-fns/locale";
import { forkJoin } from "rxjs";
import { control } from "leaflet";
import { HttpService } from "app/@components/general/services/http.service";

import { format, isAfter } from "date-fns";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "registroAsignacionProyectoPopupAddUpd",
  templateUrl: "registroAsignacionProyectoPopupAddUpd.component.html",
  styleUrls: ["registroAsignacionProyectoPopupAddUpd.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RegistroAsignacionProyectoPopupAddUpdComponent
  implements OnInit, OnDestroy
{
  form: FormGroup;
  empresas: Empresa[] = [];
  public itemForm: FormGroup;
  Botton_action = Botton_action;
  modules = modules;
  popupId = uuid.v4();
  uuidResponseSub: Subscription = new Subscription();
  enableSub: Subscription = new Subscription();
  objectSub: Subscription = new Subscription();
  id: number;
  id1: number;
  id2: number;
  totalPorcentage = 0;
  dataFormSub: Subscription = new Subscription();
  proyectoFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  proyecto: any[] = [];
  proyectoFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  date = new Date();

  itemsDelete: any;
  empresa: Empresa;
  item: RegistroUsuarioProyecto;
  validitemDuration: boolean;
  projects: any[] = [];
  errorMessage: string = "";

  maxDate: string;
  selectedDate: string;
  minDate: Date; // Fecha mínima permitida (hasta dos días atrás)

  diaYFecha: any[] = [];
  mostrarCodigoPost: boolean = false;
  datosGuardados: any[] = [];
  accion: any;
  itemsSub: Subscription;
  userId: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RegistroAsignacionProyectoPopupAddUpdComponent>,
    private store: Store<any>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private respuestaService: RespuestaService,
    private customValidatorsService: CustomValidatorsService,
    private showToastService: ShowToastService,
    private httpClient: HttpService,
    private route: ActivatedRoute
  ) {
    this.form = this.fb.group({
      checkboxes: this.fb.array([], {
        validators: this.checkAtLeastOneChecked,
      }),
    });

    this.minDate = new Date(); // Fecha actual

    this.form = this.fb.group({
      selectedDate: ["", [Validators.required]],
      fT08Inicio: [moment(), [Validators.required]],
      description: [""],
    });
  }

  ngOnInit() {
    console.log(this.popupId);
    this.itemsSub = this.store
      .select((state) => state?.auth?.primarysid)
      .subscribe((items) => {
        this.id2 = items;
      });

    this.id1 = this.data.fS07Id;

    const fecha = this.data.id.fecha;
    this.empresa = this.data?.empresa;
    this.accion = this.data.accion;
    this.validitemDuration = false;

    this.buildItemForm();

    if (this.accion == "Editar") {
      this.dataFormSub = this.httpClient
        .mostardatosDiasFechaModel(this.id1, this.id2, fecha)
        .subscribe((datos: any) => {
          this.diaYFecha = Object.values(datos);
          this.diaYFecha = this.diaYFecha[3].elementos;

          this.form = this.fb.group({
            selectedDate: ["", [Validators.required]],
            fT08Inicio: [fecha, [Validators.required]],
            description: [""],
          });
        });
    } else {
      this.mostrarCodigoPost = true;
      this.buildItemForm();

      this.objectSub = this.store
        .select((state) => state?.general?.item[this.popupId]?.object)
        .subscribe((object) => {
          if (object) {
            this.itemForm.patchValue(object);
            object?.registroUsuarioProyecto.forEach((element) => {
              this.addItem(element);
            });
          }
        });

      //close de popup
      this.uuidResponseSub = this.store
        .select((state) => state?.general?.item[this.popupId]?.uuid)
        .subscribe((uuid) => {
          if (uuid) {
            this.close();
          }
        });
      this.enableSub = this.store
        .select((state) => state?.general?.enableDesable)
        .subscribe((enableDesable) => {
          if (enableDesable.mensajePersonalizado == true) {
            this.windowAlertDataPopup(
              enableDesable.mensajePersonalizado == true
            );
          }
        });
      //consulta los datos para el formulario
      this.store.dispatch(
        generalActions.GetDataFormAction({
          id: this.empresa.fS07Id,
          module: this.modules.registroUsuarioProyecto.name,
          popupId: this.popupId,
          actionResult: undefined,
        })
      );

      this.dataFormSub = this.store
        .select((state) => state?.general?.item[this.popupId]?.dataForm)
        .subscribe((dataForm) => {
          if (dataForm) {
            this.proyecto = dataForm?.proyecto;
            this.proyectoFiltered.next(this.proyecto);
            console.log(this.proyectoFiltered);
          }
        });
      0;
      this.proyectoFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterProvincia();
        });

      this.proyectoFiltered.subscribe((data: any[]) => {
        this.projects = data;

        // Clonar this.projects para evitar mutar el objeto original
        this.diaYFecha = this.projects.map((obj) => ({ ...obj }));

        // Agregar las nuevas propiedades f_T08_Duracion y f_T08_Detalle a cada objeto
        this.diaYFecha.forEach((obj) => {
          obj.f_T08_Duracion = null;
          obj.f_T08_Detalle = null;
        });

        // Crear un FormArray con los controles basados en this.diaYFecha
        const formArray = this.diaYFecha.map((obj) =>
          this.fb.group({
            // Define aquí los controles del formulario según tus necesidades
            // Ejemplo:
            checkbox: this.fb.control(false),
            // Otros campos del formulario según tus necesidades
          })
        );

        // Asignar el FormArray al formulario 'form'
        this.form.setControl("checkboxes", this.fb.array(formArray));
      });

      this.form.valueChanges.subscribe(() => {
        this.validateTotalTime();
      });

      this.minDate = new Date();
      this.minDate.setDate(this.minDate.getDate() - 100); // Dos días atrás
    }
  }

  dateFilter = (date: Date | null): boolean => {
    const currentDate = new Date();
    return date <= currentDate && date >= this.minDate;
  };

  get checkboxes() {
    return this.form.get("checkboxes") as FormArray;
  }

  checkAtLeastOneChecked(formArray: FormArray): { [key: string]: any } | null {
    const atLeastOneChecked = formArray.controls.some(
      (control) => control.get("checked")?.value
    );
    return atLeastOneChecked ? null : { noCheckboxSelected: true };
  }
  tiempoValid: boolean;
  validateTotalTime() {
    let totalMinutes = 0;

    this.checkboxes.controls.forEach((control) => {
      if (control.get("checked")?.value && control.get("time")?.value) {
        // Obtener y parsear el valor del tiempo ingresado
        const timeValue = control.get("time")?.value;
        const [hours, minutes] = timeValue.split(":").map(Number);
        totalMinutes += hours * 60 + minutes;
      }
    });
    // Validar la suma total de minutos (no debe exceder 600 minutos = 10 horas)

    if (totalMinutes > 600 || totalMinutes <= 0) {
      this.form.setErrors({ timeExceeded: true });
      this.tiempoValid = true;
    } else {
      this.form.setErrors(null);
      this.tiempoValid = false;
    }
  }
  valor: any;
  onSubmit() {
    let fecha = this.form.get("fT08Inicio").value;

    // Verificar que 'fecha' no esté vacío y sea un objeto Moment.js válido
    if (fecha && moment(fecha).isValid()) {
      const fechaFormateada = moment(fecha).format("YYYY-MM-DD");
      fecha = fechaFormateada;
    } else {
    }

    if (this.accion == "post") {
      this.httpClient
        .verificarDia(this.itemForm.get("fS07Id").value, this.id2, fecha)
        .subscribe((datos) => {
          if (datos == 0) {
            this.showToastService.showToast(
              "danger",
              "Ya hay una fecha abierta"
            );
          } else if (datos == 1) {
            this.showToastService.showToast(
              "info",
              "Tu peticion esta siendo atendida"
            );
            this.datosGuardados = [];
            // Recorremos diaYFecha y añadimos cada objeto al array datosGuardados
            this.diaYFecha.forEach((diaYfecha, index) => {
              let fT08Id; // Variable para almacenar fT08Id final
              // Condición para determinar el valor de fT08Id
              if (diaYfecha.f_T08_Id === null) {
                fT08Id = undefined; // Asignar un valor específico si f_T08_Id es 10
              } else {
              }
              const nuevoItem = {
                fT08Id: fT08Id,
                fT02Id: diaYfecha.f_T02_Id || diaYfecha.fT02Id,
                fS07Id: this.empresa.fS07Id,
                fT08Inicio: this.form.get("fT08Inicio").value,
                verificaDato: true,
                registroUsuarioProyecto: [
                  {
                    fT08Id: fT08Id,
                    horaInicio: "",
                    horaFin: "",
                    horaDuracion: diaYfecha.f_T08_Duracion,
                    fT02Nombre: "",
                    fT08Detalle: diaYfecha.f_T08_Detalle,
                  },
                ],
              };

              setTimeout(() => {
                this.store.dispatch(
                  indexActions.PostAction({
                    data: nuevoItem,
                    module: "registroUsuarioProyecto",
                    popupId: this.popupId,
                  })
                );
              }, index * 1000);
            });
          }
        });
    } else if (this.accion == "Editar") {
      this.showToastService.showToast(
        "info",
        "Tu peticion esta siendo atendida"
      );
      this.datosGuardados = [];
      // Recorremos diaYFecha y añadimos cada objeto al array datosGuardados
      this.diaYFecha.forEach((diaYfecha, index) => {
        let fT08Id; // Variable para almacenar fT08Id final
        // Condición para determinar el valor de fT08Id
        if (diaYfecha.f_T08_Id === null) {
          fT08Id = undefined; // Asignar un valor específico si f_T08_Id es 10
        } else {
          fT08Id = diaYfecha.f_T08_Id;
        }
        const nuevoItem = {
          fT08Id: fT08Id,
          fT02Id: diaYfecha.f_T02_Id || diaYfecha.fT02Id,
          fS07Id: this.empresa.fS07Id,
          fT08Inicio: this.form.get("fT08Inicio").value,
          verificaDato: true,
          registroUsuarioProyecto: [
            {
              fT08Id: fT08Id,
              horaInicio: "",
              horaFin: "",
              horaDuracion: diaYfecha.f_T08_Duracion,
              fT02Nombre: "",
              fT08Detalle: diaYfecha.f_T08_Detalle,
            },
          ],
        };
        // Agregamos un temporizador para enviar cada solicitud con un intervalo de 1 segundo (1000 milisegundos)
        setTimeout(() => {
          this.store.dispatch(
            indexActions.PostAction({
              data: nuevoItem,
              module: "registroUsuarioProyecto",
              popupId: this.popupId,
            })
          );
        }, index * 1000); // Multiplicamos por 'index' para que cada solicitud se envíe con un segundo de retraso respecto a la anterior
      });
    }
  }

  get fS07Id() {
    return this.itemForm.get("fS07Id");
  }
  get fT02Id() {
    return this.itemForm.get("fT02Id");
  }
  get fT08Inicio() {
    return this.itemForm.get("fT08Inicio");
  }
  get fP14Kgco2() {
    return this.itemForm.get("fP14Kgco2");
  }

  get f() {
    return this.itemForm.controls;
  }

  ngOnDestroy(): void {
    this.uuidResponseSub.unsubscribe();
    this.objectSub.unsubscribe();
    // this.store.dispatch(indexActions.ClearStorageAction({ popupId: this.popupId }));
    //this.store.dispatch(generalActions.ClearStorageAction());
    this.dataFormSub.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onDestroy.unsubscribe();
    this.enableSub.unsubscribe();
  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      fT08Id: [this.id],
      fT02Id: ["", Validators.required],
      fS07Id: [this.empresa.fS07Id],
      fT08Inicio: [moment().format("YYYY-MM-DD"), Validators.required],
      verificaDato: [false],
      registroUsuarioProyecto: this.fb.array([]),
    });

    //Validación del campo Duración
    this.itemForm
      .get("registroUsuarioProyecto")
      .valueChanges.subscribe((values) => {
        let totalHours = 0;
        values.forEach((value) => {
          if (value.horaDuracion) {
            const [hours, minutes] = value.horaDuracion.split(":");
            totalHours += parseInt(hours) + parseInt(minutes) / 60;
          }
        });
        // Verificar si la hora ingresada es 0 o mayor a 8
        if (totalHours > 12 || totalHours == 0) {
          this.itemForm.get("registroUsuarioProyecto").setErrors({
            totalHoursExceeded: true,
          });
          this.validitemDuration = true;
        } else {
          this.itemForm.get("registroUsuarioProyecto").setErrors(null);
          this.validitemDuration = false;
        }
      });
  }

  addItem(item?: RegistroUsuarioProyecto) {
    //Para que se ingrese una sola vez
    if (this.itemArray.length === 0) {
      let horaI = "",
        horaF = "",
        horaD = "";
      if (item) {
        if (item.horaInicio) {
          horaI = RecortarHora(String(item.horaInicio) || "00:00");
        }
        if (item.horaFin) {
          horaF = RecortarHora(String(item.horaFin || "00:00"));
        }
        if (item.horaDuracion) {
          horaD = RecortarHora(String(item.horaDuracion || "00:00"));
        }
      }

      this.itemArray.push(
        this.fb.group({
          fT08Id: [item ? item.fT08Id : 0],
          horaInicio: [horaI || ""],
          horaFin: [horaF || ""],
          horaDuracion: [horaD || "", [Validators.required]],
          fT02Nombre: [item ? item.fT02Nombre : ""],
          fT08Detalle: [item ? item.fT08Detalle : ""],
        })
      );
      this.totalPorcentage = 0;

      if (this.itemForm.value.total > 8) {
        let respuesta = this.respuestaService.limitTime();
        this.showToastService.showToast(
          "success",
          respuesta.message,
          respuesta.detalle
        );
      }
    }
  }

  windowAlertDataPopup(id: boolean) {
    let title =
      id == true ? Botton_action.Alerta.nombre : Botton_action.Alerta.nombre;
    let dialogRef: MatDialogRef<any> = this.dialog.open(
      RegistroAsignacionProyecto1PopupAddUpdComponent,
      {
        width: modal_size.SM,
        position: { top: modal_position.top },
        disableClose: true,
        data: { title: title, id: id },
      }
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.itemForm.patchValue({ verificaDato: true });
      this.onSubmit();
    });
  }

  get itemArray(): FormArray {
    return this.itemForm.get("registroUsuarioProyecto") as FormArray;
  }

  DeleteaItemArray(item?: RegistroUsuarioProyecto, i?: number) {
    if (item.fT08Id > 0) {
      this.itemsDelete = i;
      this.deletePopUp(item);
      this.itemArray.removeAt(i);
    } else {
      this.itemArray.removeAt(i);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  private filterProvincia() {
    if (!this.proyectoFiltered) {
      return;
    }
    let search = this.proyectoFilterCtrl.value;
    if (!search) {
      this.proyectoFiltered.next(this.proyecto.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.proyectoFiltered.next(
      this.proyecto.filter(
        (item) => item.fT02Nombre.toLowerCase().indexOf(search) > -1
      )
    );
  }

  //eliminamos item
  deletePopUp(data: any) {
    let items: any[] = [];
    let item;
    item = {
      id: data?.fT08Id,
      nombre: data?.horaInicio + " " + data?.horaFin,
    };
    items.push(item);
    this.dialog.open(CrudDeletePopupComponent, {
      width: modal_size.MD,
      position: { top: modal_position.top },
      disableClose: true,
      data: {
        title:
          titulos.eliminarPopop + " " + modules.registroUsuarioProyecto.title,
        module: modules.registroUsuarioProyecto.name,
        data: items,
      },
    });
  }
}

function RecortarHora(hora: string) {
  if (hora !== null) {
    let limit = 300;
    return hora.length > limit ? hora.substring(0, limit) : hora;
  }
  return null;
}
