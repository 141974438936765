import { createAction, props } from "@ngrx/store";
export const ClearStorageAction = createAction("[MODULE] CLEAR STORAGE");

export const SetActionCreateAction = createAction(
  "[MODULE] SET ACTION CREATE",
  props<{ actionCreate: string }>()
);
export const SetTititleCrud = createAction(
  "[MODULE] SET NAME AND TITLE",
  props<{ title: string; name: string; actionResult?: string }>()
);
export const SetPath = createAction(
  "[MODULE] SET PATH",
  props<{ path: string }>()
);
export const SetErrorAction = createAction(
  "[MODULE] SET ERROR",
  props<{ error: any }>()
);
export const EnableLoadAction = createAction("[MODULE] ENABLE SPINNER LOAD");

export const DisableLoadAction = createAction("[MODULE] DISABLE SPINNER LOAD");

export const UnsetErrorAction = createAction("[MODULE] UNSET ERROR");
//valida identificacion por cliente
export const GetExistsAction = createAction(
  "[MODULE] GET EXISTS IDENTIFICATION",
  props<{ existsIdentification: any; module: any; funtion: any }>()
);

export const SetExistsAction = createAction(
  "[MODULE] SET EXISTS IDENTIFICATION",
  props<{ existsIdentification: any }>()
);
export const PostPutDeleteSuccessAction = createAction(
  "[MODULE] POST PUT DELETE SUCCESS",
  props<{
    uuid: string;
    popupId: string;
    showNotification?: boolean;
    module?: string;
    reloadIndex?: boolean;
    tipoMensaje?: string;
    cerrarPupop?: boolean;
  }>()
);
//eliminar datos
export const DeleteItemsAction = createAction(
  "[MODULE] DELETE ITEMS",
  props<{
    module: string;
    actionResult?: string;
    ids: any;
    popupId: string;
    reloadIndex?: boolean;
    tipoMensaje?: string;
    cerrarPupop?: boolean;
  }>()
);

export const DeleteItemsAction2 = createAction(
  "[MODULE] DELETE ITEMS",
  props<{
    module: string;
    actionResult?: string;
    id1: any;
    id2: any;
    fecha: any;
    popupId: string;
    reloadIndex?: boolean;
    tipoMensaje?: string;
    cerrarPupop?: boolean;
  }>()
);

export const SetDeleteDataPopupItemsAction = createAction(
  "[MODULE] DELETE ITEMS",
  props<{ uuid?: string }>()
);
//Anular datos
export const AnularItemsAction = createAction(
  "[MODULE] ANULAR ITEMS",
  props<{
    module: string;
    actionResult: string;
    ids: any;
    popupId: string;
    reloadIndex: boolean;
    tipoMensaje?: string;
  }>()
);

export const SetAnularDataPopupItemsAction = createAction(
  "[MODULE] REINICIAR ANULAR",
  props<{ reiniciar?: any }>()
);

export const GetDataFormAction = createAction(
  "[MODULE] GET METADATOS",
  props<{
    id?: any;
    id1?: any;
    parameters?: any;
    module: string;
    popupId: string;
    actionResult?: string;
  }>()
);

export const SetDataFormAction = createAction(
  "[MODULE] SET METADATOS",
  props<{ dataForm: any; popupId: string }>()
);

export const GetObjectAction = createAction(
  "[MODULE] GET OBJECT",
  props<{ id: string; module: string; popupId: string; actionResult: string }>()
);

export const SetObjectAction = createAction(
  "[ITEM] SET OBJECT",
  props<{ object: any; popupId: string }>()
);

//enable y deshable
export const SetEnableDesableButton = createAction(
  "[ITEM] SET BUTTON  Enable Desable Button",
  props<{ enableDesable?: any }>()
);
